import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

// import { setupPreviewData } from 'utils';
import { Slice } from '../slices/Slice';
import './investPage.scss';

const InvestPage = ({ location, data: staticData }) => {
  const {
    prismicInvest: { data: pageData },
    // } = setupPreviewData(staticData);
  } = staticData;

  const {
    body: sliceData,
    display_invest_dropdown: displayDropdown,
    header_tagline: tagline,
    header_title: title,
    header_content: content,
  } = pageData;

  const headerProps = { displayDropdown, tagline, title, content };
  const layoutProps = { location, pageData, headerProps, bodyClassName: 'invest-page' };

  return (
    <Layout {...layoutProps}>
      <div className="page-wrapper">
        {sliceData.map(slice => (
          <Slice template="invest-page" key={slice.id} data={slice} location={location} />
        ))}
      </div>
    </Layout>
  );
};

// export default InvestPage;
export default withPrismicPreview(InvestPage);

export const pageQuery = graphql`
  {
    prismicInvest {
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        display_invest_dropdown
        header_tagline {
          text
        }
        header_title {
          text
        }
        header_content {
          html
        }
        body {
          ...CtaInvestFragment
          ...BlogFeedInvestFragment
          ...TilesInvestFragment
        }
      }
    }
    prismicBlogLandingPage {
      uid
    }
    prismicSettings {
      data {
        case_study_category {
          uid
        }
      }
    }
    ...WarningStatementsFragment
    ...DealGridSettingsFragment
    ...CaseStudyGridSettingsFragment
  }
`;
